import * as React from 'react';
// import '../sass/main.scss';

import SvgBorderRounded from '../components/svgBorderRounded';
import FeatherIcon from 'feather-icons-react';
import LayoutDefault from '../layouts/default';
import IndexHeader from '../components/indexHeader';

// markup
const IndexPage = () => {
  return (
    <>
      <LayoutDefault>
        <IndexHeader />
        <section className="bg-white py-10" id="get-started">
          <div className="container px-5">
            <div className="row gx-5 text-center">
              <div className="col-lg-4 mb-5 mb-lg-0">
                <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4">
                  <FeatherIcon className="" icon="phone" />
                </div>
                <h2>
                  Discovery call
                  {/* Analyze Processes */}
                </h2>
                <p className="mb-0">
                  We start by creating a set of brand guidelines and standards
                </p>
              </div>
              <div className="col-lg-4 mb-5 mb-lg-0">
                <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4">
                  <FeatherIcon className="" icon="command" />
                </div>
                <h2>Analyze Processes</h2>
                <p className="mb-0">
                  Our award winning development team creates personalized
                  digital experiences
                </p>
              </div>
              <div className="col-lg-4">
                <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4">
                  <FeatherIcon className="" icon="list" />
                </div>
                <h2>Present Solution's</h2>
                <p className="mb-0">
                  Our products are created on time, and to your exact
                  specifications
                </p>
              </div>
            </div>
            <div className="row gx-5 text-center mt-5">
              <div className="col-lg-4 offset-lg-2 mb-5 mb-lg-0">
                <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4">
                  <FeatherIcon className="" icon="layout" />
                </div>
                <h2>Design</h2>
                <p className="mb-0">
                  We start by creating a set of brand guidelines and standards
                </p>
              </div>
              <div className="col-lg-4 mb-5 mb-lg-0">
                <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4">
                  <FeatherIcon className="" icon="check-square" />
                </div>
                <h2>Develop</h2>
                <p className="mb-0">
                  Our award winning development team creates personalized
                  digital experiences
                </p>
              </div>
            </div>
            <div className="row gx-5 text-center mt-5">
              <div className="col-lg-4 offset-lg-4 mb-5 mb-lg-0">
                <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4">
                  <FeatherIcon className="" icon="activity" />
                </div>
                <h2>Deploy</h2>
                <p className="mb-0">
                  Our award winning development team creates personalized
                  digital experiences
                </p>
              </div>
            </div>
          </div>
          <div className="svg-border-rounded text-dark">
            {/* Rounded SVG Border*/}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 144.54 17.34"
              preserveAspectRatio="none"
              fill="currentColor"
            >
              <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0" />
            </svg>
          </div>
        </section>

        <section className="bg-dark py-10">
          <div className="container px-5 z-1">
            <div className="card mb-5" data-aos="fade-right">
              <div className="row g-0">
                <div className="col-md-6">
                  <img
                    className="img-fluid"
                    src="https://source.unsplash.com/6LBBOwkPzyQ/800x550"
                    alt="..."
                  />
                </div>
                <div className="col-md-6">
                  <div className="card-body d-flex align-items-center justify-content-center h-100 flex-column">
                    <h3 className="card-title fw-bold text-uppercase mb-2">
                      Cocooil
                    </h3>
                    <p className="card-text fw-light mb-4">Body Oil</p>
                    <a className="btn btn-outline-dark fw-500" href="#!">
                      View Case Study
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-5" data-aos="fade-left">
              <div className="row g-0">
                <div className="col-md-6 order-1 order-md-0">
                  <div className="card-body d-flex align-items-center justify-content-center h-100 flex-column">
                    <h3 className="card-title fw-bold text-uppercase mb-2">
                      Balitello
                    </h3>
                    <p className="card-text fw-light mb-4">Designer Socks</p>
                    <a className="btn btn-outline-dark fw-500" href="#!">
                      View Case Study
                    </a>
                  </div>
                </div>
                <div className="col-md-6 order-0 order-md-1">
                  <img
                    className="img-fluid"
                    src="https://source.unsplash.com/XfHvAwu6E8E/800x550"
                    alt="..."
                  />
                </div>
              </div>
            </div>
            <div className="card" data-aos="fade-right">
              <div className="row g-0">
                <div className="col-md-6">
                  <img
                    className="img-fluid"
                    src="https://source.unsplash.com/7LNatQYMzm4/800x550"
                    alt="..."
                  />
                </div>
                <div className="col-md-6">
                  <div className="card-body d-flex align-items-center justify-content-center h-100 flex-column">
                    <h3 className="card-title fw-bold text-uppercase mb-2">
                      Headphones
                    </h3>
                    <p className="card-text fw-light mb-4">Premium Audio</p>
                    <a className="btn btn-outline-dark fw-500" href="#!">
                      View Case Study
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="svg-border-rounded  mt-n15"
            style={{ color: '#2c2e79' }}
          >
            {/* Rounded SVG Border*/}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 144.54 17.34"
              preserveAspectRatio="none"
              fill="currentColor"
            >
              <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0" />
            </svg>
          </div>
        </section>
        <section className="bg-white py-10">
          <div className="container px-5">
            <div className="row gx-5 justify-content-center">
              <div className="col-xl-6 col-lg-8 col-md-10 text-center my-10 py-10">
                <h2>
                  We're here to help the <br /> Systemic Problem Solvers (NGOs)
                </h2>
                <p className="lead text-gray-500 mb-4">
                  We help NGOs with digital transformation, we're here to spread
                  the strength of tech to solve as many thing we can with what
                  we have.
                </p>
                <a className="btn btn-primary fw-500" href="#!">
                  Contact us
                </a>
              </div>
            </div>
          </div>
          <div className="svg-border-rounded text-black">
            {/* Rounded SVG Border*/}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 144.54 17.34"
              preserveAspectRatio="none"
              fill="currentColor"
            >
              <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0" />
            </svg>
          </div>
        </section>
      </LayoutDefault>
    </>
  );
};

export default IndexPage;
